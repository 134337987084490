import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import ClickOutside from 'vuetify/lib/directives/click-outside';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          def: ClickOutside,
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      staticClass: "vue-simple-context-menu",
      attrs: { id: _vm.elementId },
    },
    _vm._l(_vm.options, function (item, key) {
      return _c(
        VTooltip,
        {
          key: key,
          attrs: { right: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "draggable",
                        style: _vm.toolStyle(key, _vm.options.length),
                        attrs: { align: "center" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.optionClicked(item)
                          },
                        },
                      },
                      [
                        _c(
                          "img",
                          _vm._g(
                            {
                              staticStyle: {
                                "vertical-align": "middle",
                                border: "2 solid grey",
                                "-webkit-box-shadow":
                                  "5px 5px 20px 0px rgba(0,0,0,0.75)",
                                "-moz-box-shadow":
                                  "5px 5px 20px 0px rgba(0,0,0,0.40)",
                                "box-shadow":
                                  "5px 5px 20px 0px rgba(0,0,0,0.40)",
                              },
                              attrs: {
                                valign: "middle",
                                onmouseover:
                                  "this.height=this.height*1.5;this.width=this.width*1.5;this.left=this.left-this.width*0.5;this.right=this.right-this.width*0.5;",
                                onmouseout:
                                  "this.height=this.height/1.5;this.width=this.width/1.5;this.left=this.left+this.width*0.5;this.right=this.right+this.width*0.5;",
                                height: "30px",
                                width: "30px",
                                src: item.src,
                              },
                            },
                            on
                          )
                        ),
                        _c(VChip, _vm._g({}, on), [
                          _vm._v(_vm._s(item.label)),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _c(
            VCard,
            {
              staticClass: "mx-auto",
              attrs: { "max-width": "500", outlined: "" },
            },
            [
              _c(
                VListItem,
                { attrs: { "three-line": "" } },
                [
                  _c(
                    VListItemContent,
                    [
                      _c("div", { staticClass: "overline mb-4" }, [
                        _vm._v(_vm._s(item.component)),
                      ]),
                      _c(
                        VListItemTitle,
                        { staticClass: "headline mb-1" },
                        [_vm._v(_vm._s(item.label))]
                      ),
                      _c(VListItemSubtitle, [
                        _vm._v(_vm._s(item.description)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    VListItemAvatar,
                    { attrs: { tile: "", size: "80", color: "white" } },
                    [_c(VImg, { attrs: { src: item.src } })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }