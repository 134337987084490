var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("edge-element", {
        attrs: {
          selectable: "",
          connectable: "",
          deletable: "",
          vertices: _vm.vertices,
          from: _vm.value.from,
          to: _vm.value.to,
          _style: _vm.style_,
          label: _vm.value.name,
        },
        on: {
          "update:vertices": function ($event) {
            _vm.vertices = $event
          },
          "update:from": function ($event) {
            return _vm.$set(_vm.value, "from", $event)
          },
          "update:to": function ($event) {
            return _vm.$set(_vm.value, "to", $event)
          },
          selectShape: _vm.selectedActivity,
          deSelectShape: _vm.deSelectedActivity,
          removeShape: _vm.onRemoveShape,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }