import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "canvas-panel", staticStyle: { left: "0" } }, [
        _c(
          "div",
          { key: _vm.eleCnt },
          [
            _vm.showOverlay
              ? _c(
                  VOverlay,
                  [
                    _c(
                      VCol,
                      { attrs: { align: "center" } },
                      [
                        _c("div", [_vm._v(_vm._s(_vm.showOverlay))]),
                        _c(
                          VProgressCircular,
                          { attrs: { indeterminate: "", size: "64" } },
                          [
                            _c(VBtn, {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeOverlay()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              VAlert,
              {
                staticStyle: {
                  "align-self": "center",
                  position: "absolute",
                  "z-index": "999",
                  "margin-left": "35%",
                },
                attrs: {
                  dense: "",
                  elevation: "6",
                  "colored-border": "",
                  value: _vm.alertInfo.show,
                  border: _vm.alertInfo.border,
                  type: _vm.alertInfo.type,
                  "max-width": _vm.alertInfo.maxWidth,
                  color: _vm.alertInfo.color,
                  width: "100%",
                },
              },
              [
                _c("div", { staticStyle: { color: "black" } }, [
                  _vm._v(" " + _vm._s(_vm.alertInfo.text)),
                ]),
                _vm.alertInfo.link
                  ? _c(
                      "a",
                      { attrs: { target: "_blank", href: _vm.alertInfo.link } },
                      [_vm._v("Github Persenal Access Token 발행 방법")]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticStyle: { "text-align": "right" } },
                  [
                    _vm.alertInfo.submit
                      ? _c(
                          VBtn,
                          {
                            staticStyle: { "margin-right": "5px" },
                            attrs: { small: "", color: "green", dark: "" },
                            on: {
                              click: function ($event) {
                                return _vm.alertSubmit(_vm.alertInfo.fnNum)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.alertInfo.submit) +
                                "\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      VBtn,
                      {
                        attrs: { small: "", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.alertClose(_vm.alertInfo.fnNum)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("word.close")))]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              VLayout,
              [
                _vm.value
                  ? _c(
                      "opengraph",
                      {
                        ref: "opengraph",
                        attrs: {
                          "focus-canvas-on-select": "",
                          wheelScalable: "",
                          labelEditable: true,
                          sliderLocationScale: _vm.sliderLocationScale,
                          dragPageMovable: _vm.dragPageMovable,
                          enableContextmenu: false,
                          enableRootContextmenu: false,
                          enableHotkeyCtrlC: false,
                          enableHotkeyCtrlV: false,
                          enableHotkeyDelete: false,
                          enableHotkeyCtrlZ: false,
                          enableHotkeyCtrlD: false,
                          enableHotkeyCtrlG: false,
                          slider: true,
                          movable: !_vm.isReadOnlyModel,
                          resizable: !_vm.isReadOnlyModel,
                          selectable: true,
                          connectable: !_vm.isReadOnlyModel,
                          autoSliderUpdate: true,
                          imageBase: _vm.imageBase,
                        },
                        on: {
                          canvasReady: _vm.bindEvents,
                          connectShape: _vm.onConnectShape,
                        },
                      },
                      [
                        _vm._l(_vm.perspectives, function (perspective, index) {
                          return _c(
                            _vm.getComponentByClassName(perspective._type),
                            {
                              key: index,
                              tag: "component",
                              on: {
                                click: function ($event) {
                                  return _vm.changeCategory(
                                    _vm.perspectives[index]
                                  )
                                },
                              },
                              model: {
                                value: _vm.perspectives[index],
                                callback: function ($$v) {
                                  _vm.$set(_vm.perspectives, index, $$v)
                                },
                                expression: "perspectives[index]",
                              },
                            }
                          )
                        }),
                        _vm._l(
                          Object.keys(_vm.value.elements),
                          function (elementId) {
                            return elementId &&
                              _vm.value.elements[elementId] &&
                              _vm.value.elements
                              ? _c(
                                  _vm.getComponentByClassName(
                                    _vm.value.elements[elementId]._type
                                  ),
                                  {
                                    ref: elementId,
                                    refInFor: true,
                                    tag: "component",
                                    attrs: {
                                      value: _vm.value.elements[elementId],
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.value.elements,
                                          elementId,
                                          $event
                                        )
                                      },
                                    },
                                  }
                                )
                              : _vm._e()
                          }
                        ),
                        _vm._l(
                          Object.keys(_vm.value.relations),
                          function (relationId) {
                            return relationId &&
                              _vm.value.relations[relationId] &&
                              _vm.value.relations
                              ? _c(
                                  _vm.getComponentByClassName(
                                    _vm.value.relations[relationId]._type
                                  ),
                                  {
                                    ref: relationId,
                                    refInFor: true,
                                    tag: "component",
                                    attrs: {
                                      value: _vm.value.relations[relationId],
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.value.relations,
                                          relationId,
                                          $event
                                        )
                                      },
                                    },
                                  }
                                )
                              : _vm._e()
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._t("undoRedo", function () {
                  return [
                    !_vm.isReadOnlyModel
                      ? _c(
                          VFlex,
                          [
                            _c(
                              VRow,
                              { staticClass: "gs-modeling-undo-redo" },
                              [
                                _c(
                                  VTooltip,
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                VBtn,
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "gs-model-z-index-2 gs-undo-opacity-hover",
                                                    attrs: {
                                                      disabled:
                                                        _vm.isUndoDisabled,
                                                      text: "",
                                                      small: "",
                                                      right: "",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.undo()
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    VIcon,
                                                    { attrs: { medium: "" } },
                                                    [_vm._v("mdi-undo")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      695928026
                                    ),
                                  },
                                  [_c("span", [_vm._v("Undo")])]
                                ),
                                _c(
                                  VTooltip,
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                VBtn,
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "gs-model-z-index-2 gs-undo-opacity-hover",
                                                    attrs: {
                                                      disabled:
                                                        _vm.isRedoDisabled,
                                                      text: "",
                                                      small: "",
                                                      right: "",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.redo()
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    VIcon,
                                                    { attrs: { medium: "" } },
                                                    [_vm._v("mdi-redo")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1134616950
                                    ),
                                  },
                                  [_c("span", [_vm._v("Redo")])]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                }),
                _vm.isMobile
                  ? _c(
                      "div",
                      [
                        _c(
                          VSpeedDial,
                          {
                            staticClass: "business-mobile-speed-dial",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function () {
                                    return [
                                      _c(
                                        VBtn,
                                        {
                                          attrs: {
                                            color: "blue darken-2",
                                            dark: "",
                                            fab: "",
                                            small: "",
                                          },
                                          model: {
                                            value: _vm.fab,
                                            callback: function ($$v) {
                                              _vm.fab = $$v
                                            },
                                            expression: "fab",
                                          },
                                        },
                                        [
                                          _vm.fab
                                            ? _c(VIcon, [
                                                _vm._v(
                                                  "\n                                    mdi-playlist-remove\n                                "
                                                ),
                                              ])
                                            : _c(VIcon, [
                                                _vm._v(
                                                  "\n                                    mdi-playlist-check\n                                "
                                                ),
                                              ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2289717986
                            ),
                            model: {
                              value: _vm.fab,
                              callback: function ($$v) {
                                _vm.fab = $$v
                              },
                              expression: "fab",
                            },
                          },
                          [
                            _c(
                              VCol,
                              { staticStyle: { "margin-right": "390px" } },
                              [
                                _c(
                                  VBtn,
                                  {
                                    attrs: { small: "", text: "", dark: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveComposition("save")
                                      },
                                    },
                                  },
                                  [_vm._v("save\n                            ")]
                                ),
                                _vm.isOwnModel && !_vm.isReadOnlyModel
                                  ? _c(
                                      VBtn,
                                      {
                                        attrs: { text: "", small: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openInviteUsers()
                                          },
                                        },
                                      },
                                      [
                                        _c(VIcon, [
                                          _vm._v(_vm._s(_vm.icon.share)),
                                        ]),
                                        _vm._v(
                                          "\n                                SHARE\n                            "
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  VBtn,
                                  {
                                    staticClass: "action-btn",
                                    attrs: { text: "", color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.generateImplementationModel()
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: {
                                        icon: "mdi:head-cog-outline",
                                        height: "24",
                                      },
                                    }),
                                    _c("div", [_vm._v("implementation model")]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._t("top", function () {
                  return [
                    _c(
                      VFlex,
                      { staticStyle: { align: "start" } },
                      [
                        _c(
                          VRow,
                          {
                            staticClass:
                              "gs-model-z-index-1 business-model-canvas-top-menu",
                          },
                          [
                            _c(
                              VRow,
                              {
                                staticStyle: {
                                  "margin-top": "10px",
                                  width: "150px",
                                },
                                attrs: { align: "start" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "business-model-canvas-mobile-home-button",
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/" } },
                                      [
                                        _c(
                                          VIcon,
                                          {
                                            staticStyle: {
                                              height: "24px",
                                              "margin-top": "38px",
                                              "margin-right": "5px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            mdi-home\n                                        "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._t("projectName", function () {
                                  return [
                                    _c(
                                      VCol,
                                      {
                                        attrs: {
                                          align: "start",
                                          id: "project-name",
                                        },
                                      },
                                      [
                                        _c(VTextField, {
                                          staticStyle: { "z-index": "2" },
                                          attrs: {
                                            disabled: _vm.isReadOnlyModel,
                                            label: "Project Name",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.unselectedAll.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.projectName,
                                            callback: function ($$v) {
                                              _vm.projectName = $$v
                                            },
                                            expression: "projectName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                }),
                              ],
                              2
                            ),
                            _c(
                              "div",
                              { staticClass: "action-btn-box" },
                              [
                                _vm.isOwnModel
                                  ? _c(
                                      VRow,
                                      [
                                        _c(
                                          VCol,
                                          { attrs: { align: "right" } },
                                          [
                                            _c(
                                              VBtn,
                                              {
                                                staticClass: "action-btn",
                                                attrs: {
                                                  text: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.generateImplementationModel()
                                                  },
                                                },
                                              },
                                              [
                                                _c("Icon", {
                                                  staticStyle: {
                                                    "margin-right": "5px",
                                                  },
                                                  attrs: {
                                                    icon: "mdi:head-cog-outline",
                                                    height: "24",
                                                  },
                                                }),
                                                _c("div", [
                                                  _vm._v(
                                                    "implementation model"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              VBtn,
                                              {
                                                staticClass: "action-btn",
                                                attrs: { text: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.storageDialogReady(
                                                      "save"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(VIcon, [
                                                  _vm._v(_vm._s(_vm.icon.save)),
                                                ]),
                                                _vm._v(
                                                  "\n                                            SAVE\n                                        "
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.isOwnModel &&
                                            _vm.isServerModel &&
                                            !_vm.isReadOnlyModel
                                              ? _c(
                                                  VBtn,
                                                  {
                                                    attrs: { text: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openInviteUsers()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(VIcon, [
                                                      _vm._v(
                                                        _vm._s(_vm.icon.share)
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      "\n                                            SHARE\n                                        "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      VRow,
                                      [
                                        _vm.isReadOnlyModel
                                          ? _c(
                                              VBtn,
                                              {
                                                staticClass:
                                                  "gs-model-z-index-1 es-hide-fork-btn",
                                                staticStyle: {
                                                  "margin-right": "5px",
                                                  "margin-top": "15px",
                                                },
                                                attrs: {
                                                  text: "",
                                                  color: _vm.joinRequestedText
                                                    .show
                                                    ? "primary"
                                                    : "success",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.requestInviteUser()
                                                  },
                                                },
                                              },
                                              [
                                                _vm.joinRequestedText.show
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(VIcon, [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.icon.join
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.joinRequestedText.text
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.isReadOnlyModel
                                          ? _c(
                                              VBtn,
                                              {
                                                staticClass:
                                                  "gs-model-z-index-1 es-hide-fork-btn",
                                                staticStyle: {
                                                  "margin-right": "5px",
                                                  "margin-top": "15px",
                                                },
                                                attrs: {
                                                  text: "",
                                                  disabled: _vm.disableBtn,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.saveComposition(
                                                      "fork"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(VIcon, [
                                                  _vm._v(_vm._s(_vm.icon.fork)),
                                                ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "es-hide-fork",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        FORK\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                }),
                _c(
                  "a",
                  {
                    staticClass: "strategyzer-link",
                    attrs: {
                      href: "https://www.strategyzer.com/",
                      target: "_blank",
                    },
                  },
                  [_vm._v("strategyzer.com")]
                ),
                _vm._t("palette", function () {
                  return [
                    _c(
                      VCard,
                      { staticClass: "business-model-canvas-sticker" },
                      _vm._l(
                        _vm.elementTypes,
                        function (category, categoryIndex) {
                          return !_vm.isReadOnlyModel
                            ? _c(
                                VTooltip,
                                {
                                  key: categoryIndex,
                                  attrs: { right: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "draggable",
                                                attrs: {
                                                  align: "center",
                                                  _component:
                                                    category[0].component,
                                                  _width: category[0].width,
                                                  _height: category[0].height,
                                                  _description:
                                                    category[0].description,
                                                  _label: category[0].label,
                                                },
                                                on: {
                                                  mouseover: function ($event) {
                                                    return _vm.changeCategory(
                                                      categoryIndex
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "img",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        height: "30px",
                                                        width: "30px",
                                                        src: category[0].src,
                                                      },
                                                    },
                                                    on
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(category[0].component)),
                                  ]),
                                ]
                              )
                            : _vm._e()
                        }
                      ),
                      1
                    ),
                    _vm._l(
                      _vm.elementTypes,
                      function (category, categoryIndex) {
                        return _c("div", { key: categoryIndex }, [
                          _vm.selectedCategoryIndex == categoryIndex
                            ? _c(
                                "div",
                                _vm._l(category, function (item, key) {
                                  return _c(
                                    VTooltip,
                                    {
                                      key: key,
                                      attrs: { right: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "draggable",
                                                    style: _vm.toolStyle(
                                                      key,
                                                      categoryIndex,
                                                      category.length
                                                    ),
                                                    attrs: {
                                                      align: "center",
                                                      _component:
                                                        item.component,
                                                      _width: item.width,
                                                      _height: item.height,
                                                      _description:
                                                        item.description,
                                                      _label: item.label,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addBusinessTactic(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "img",
                                                      _vm._g(
                                                        {
                                                          staticStyle: {
                                                            "vertical-align":
                                                              "middle",
                                                            border:
                                                              "2 solid grey",
                                                            "-webkit-box-shadow":
                                                              "5px 5px 20px 0px rgba(0,0,0,0.75)",
                                                            "-moz-box-shadow":
                                                              "5px 5px 20px 0px rgba(0,0,0,0.40)",
                                                            "box-shadow":
                                                              "5px 5px 20px 0px rgba(0,0,0,0.40)",
                                                          },
                                                          attrs: {
                                                            valign: "middle",
                                                            onmouseover:
                                                              "this.height=this.height*1.5;this.width=this.width*1.5;this.left=this.left-this.width*0.5;this.right=this.right-this.width*0.5;",
                                                            onmouseout:
                                                              "this.height=this.height/1.5;this.width=this.width/1.5;this.left=this.left+this.width*0.5;this.right=this.right+this.width*0.5;",
                                                            height: "40px",
                                                            width: "40px",
                                                            src: item.src,
                                                            border: "2",
                                                          },
                                                        },
                                                        on
                                                      )
                                                    ),
                                                    _c(
                                                      VChip,
                                                      _vm._g({}, on),
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.label)
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        VCard,
                                        {
                                          staticClass: "mx-auto",
                                          attrs: {
                                            "max-width": "500",
                                            outlined: "",
                                          },
                                        },
                                        [
                                          _c(
                                            VListItem,
                                            { attrs: { "three-line": "" } },
                                            [
                                              _c(
                                                VListItemContent,
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "overline mb-4",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.component)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    VListItemTitle,
                                                    {
                                                      staticClass:
                                                        "headline mb-1",
                                                    },
                                                    [_vm._v(_vm._s(item.label))]
                                                  ),
                                                  _c(VListItemSubtitle, [
                                                    _vm._v(
                                                      _vm._s(item.description)
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VListItemAvatar,
                                                {
                                                  attrs: {
                                                    tile: "",
                                                    size: "80",
                                                    color: "white",
                                                  },
                                                },
                                                [
                                                  _c(VImg, {
                                                    attrs: { src: item.src },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ])
                      }
                    ),
                  ]
                }),
              ],
              2
            ),
            _vm.showParticipantPanel
              ? _c("ParticipantPanel", {
                  attrs: { lists: _vm.participantLists },
                })
              : _vm._e(),
            _c("model-canvas-share-dialog", {
              attrs: {
                showDialog: _vm.inviteDialog,
                checkPublic: _vm.showPublicModel,
                canvas: _vm.canvas,
                canvasComponentName: "business-model-canvas",
              },
              on: {
                all: _vm.invitePublic,
                apply: _vm.applyInviteUsers,
                close: _vm.closeInviteUsers,
                add: _vm.addInviteUser,
                remove: _vm.removeInviteUser,
              },
              model: {
                value: _vm.inviteLists,
                callback: function ($$v) {
                  _vm.inviteLists = $$v
                },
                expression: "inviteLists",
              },
            }),
            _c("model-storage-dialog", {
              attrs: {
                condition: _vm.storageCondition,
                showDialog: _vm.showStorageDialog,
              },
              on: {
                save: _vm.saveModel,
                fork: _vm.forkModel,
                backup: _vm.backupModel,
                close: _vm.storageDialogCancel,
              },
            }),
            _c("modeler-image-generator", { ref: "modeler-image-generator" }),
          ],
          1
        ),
      ]),
      _c("AutoModelingDialog", {
        ref: "autoModelingDialog",
        attrs: {
          mode: "es",
          showDialog: _vm.dialogModeOfAutoModeling,
          showChat: _vm.dialogModeOfAutoModeling,
          BMState: _vm.BMState,
          projectId: _vm.projectId,
        },
        on: {
          createModel: _vm.createModel,
          clearModelValue: _vm.clearModelValue,
          startCreateModel: _vm.openEventStorming,
        },
      }),
      _vm.projectId
        ? _c("GeneratorUI", {
            ref: "generatorUI",
            attrs: {
              projectId: _vm.projectId,
              modelValue: _vm.value,
              defaultInputData: _vm.defaultGeneratorUiInputData,
            },
            on: {
              createModel: _vm.createModel,
              clearModelValue: _vm.clearModelValue,
              modificateModel: _vm.modificateModel,
            },
          })
        : _vm._e(),
      _vm._l(_vm.filteredMouseEventHandlers, function (otherMouseEvent, email) {
        return _c(
          "div",
          { key: email },
          [
            _c("MouseCursorComponent", {
              attrs: { mouseEvent: otherMouseEvent, email: email },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }