var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sub-controller", {
        attrs: { image: "../business/" + _vm.perspective + ".png" },
        on: { click: _vm.showAddComponent },
      }),
      _c("business-model-tooltip", {
        ref: "modelTooltip",
        attrs: { elementId: _vm.elementId, options: _vm.elementList },
        on: { "option-clicked": _vm.createModelElement },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }