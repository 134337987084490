import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VChip,
        {
          staticClass: "ma-5",
          attrs: { color: _vm.value ? "primary" : "grey" },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.value ? _vm.value.projectName : "Not Selected") +
              "\n    "
          ),
        ]
      ),
      !_vm.isReadOnly
        ? _c(
            "EventStormingListPage",
            {
              attrs: { buinessName: _vm.search, searchType: "es" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "action",
                    fn: function ({ project }) {
                      return _c(
                        VBtn,
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.select(project)
                            },
                          },
                        },
                        [_vm._v("\n            SELECT\n        ")]
                      )
                    },
                  },
                ],
                null,
                false,
                3212764777
              ),
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }),
              _c("div", { attrs: { slot: "chips" }, slot: "chips" }),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }