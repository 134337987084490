import { render, staticRenderFns } from "./BusinessRelation.vue?vue&type=template&id=4c8cd6da&scoped=true"
import script from "./BusinessRelation.vue?vue&type=script&lang=js"
export * from "./BusinessRelation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c8cd6da",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kibumpark/Desktop/uEngine-project/platform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4c8cd6da')) {
      api.createRecord('4c8cd6da', component.options)
    } else {
      api.reload('4c8cd6da', component.options)
    }
    module.hot.accept("./BusinessRelation.vue?vue&type=template&id=4c8cd6da&scoped=true", function () {
      api.rerender('4c8cd6da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/designer/business-model-canvas/elements/BusinessRelation.vue"
export default component.exports