import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { wrap: "" } },
    [
      _c(
        VNavigationDrawer,
        {
          staticStyle: { width: "400px" },
          attrs: { absolute: "", permanent: "", right: "" },
        },
        [
          _c(
            VList,
            { staticClass: "pa-1" },
            [
              _c(
                VListItem,
                [
                  _c(VListItemAvatar, [
                    _c("img", { attrs: { src: _vm.image } }),
                  ]),
                  _c(VListItemTitle, { staticClass: "headline" }, [
                    _vm._v(_vm._s(_vm.titleName) + "\n                "),
                  ]),
                  _c(VTooltip, {
                    attrs: { top: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              VBtn,
                              {
                                attrs: { icon: "" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.closePanel()
                                  },
                                },
                              },
                              [
                                _c(
                                  VIcon,
                                  { attrs: { color: "grey lighten-1" } },
                                  [_vm._v("mdi-close")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VList,
            { staticClass: "pt-0", attrs: { dense: "", flat: "" } },
            [
              _c(VDivider),
              _c(
                VCard,
                { attrs: { outlined: "" } },
                [
                  _c(
                    VCardText,
                    [
                      _c("span", { staticClass: "headline" }, [
                        _vm._v("Basic Info"),
                      ]),
                      _c(VTextField, {
                        attrs: {
                          label: "Name",
                          autofocus: "",
                          disabled: _vm.isReadOnly,
                        },
                        model: {
                          value: _vm.value.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "name", $$v)
                          },
                          expression: "value.name",
                        },
                      }),
                      _vm.value.description != null
                        ? _c(VTextarea, {
                            attrs: {
                              outlined: "",
                              name: "input-7-4",
                              label: "description",
                              disabled: _vm.isReadOnly,
                            },
                            model: {
                              value: _vm.value.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "description", $$v)
                              },
                              expression: "value.description",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(VCard),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }